import { FaFacebookF, FaTelegram, FaInstagram } from "react-icons/fa";
import { AiOutlineGithub } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const iconsTab = [
    {
      icon: <FaFacebookF />,
      link: "https://www.facebook.com/profile.php?id=100075974059076",
    },
    { icon: <AiOutlineGithub />, link: "https://github.com/abdurahmon27" },
    { icon: <FaTelegram />, link: "https://t.me/abdurahmon_mamadiyorov" },
    { icon: <FaInstagram />, link: "https://instagram.com/abdurahmon._27" },
  ];
  const { t } = useTranslation();
  const NavLink = ({ to, label }) => (
    <Link
      to={to}
      className="text-default dark:text-dark transition duration-300"
    >
      {label}
    </Link>
  );
  return (
    <>
      <footer className="bg-default dark:bg-dark">
        <div className="container mx-auto  py-[10rem]">
          {/* footer div all */}
          <div className="flex justify-center  flex-col md:flex-col items-center md:items-start  md:gap-[5rem] text-left">
            {/* middle div */}
            <div className="flex flex-row max-lg:flex-col mx-auto gap-8 relative justify-center items-center">
              <p className="text-[16px] text-default dark:text-dark font-medium hover:font-bold">
                <NavLink to={"/"} label={t("Home")} />
              </p>
              <p className="text-[16px] text-default dark:text-dark font-medium hover:font-bold">
                <NavLink to={"/courses"} label={t("Courses")} />
              </p>
              <p className="text-[16px] text-default dark:text-dark font-medium hover:font-bold">
                <NavLink to={"/careers"} label={t("Careers")} />
              </p>
              <p className="text-[16px] text-default dark:text-dark font-medium hover:font-bold">
                <NavLink to={"/Contact"} label={t("Contact")} />
              </p>
              <p className="text-[16px] text-default dark:text-dark font-medium hover:font-bold">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://bekzotovich.uz/"
                >
                  Bekzotovich
                </a>
              </p>
            </div>
            <div className="flex flex-col w-1/2 md:p-0 py-4 gap-8 mx-auto items-center justify-center">
              <span className="font-semibold text-2xl text-default dark:text-dark">MindMingle</span>
              <p className="text-[15px] font-medium  text-default dark:text-dark">
                Bla Bla Bla.). I've no idea for this app
              </p>
              <div className="flex gap-7 text-[18px]  text-default dark:text-dark justify-center md:justify-start">
                {iconsTab.map(({ icon, link }, index) => {
                  return (
                    <a key={index} href={link} target="_blank" rel="noreferrer">
                      <div
                        className="text-2xl p-2 rounded-full hover:text-white"
                        style={{ transition: "all 0.3s" }}
                      >
                        {icon}
                      </div>
                    </a>
                  );
                })}
              </div>

              <p className="text-[16px] font-medium  text-default dark:text-dark">
                Privacy Policy | © {new Date().getFullYear()} mindmingle <br />{" "}
                Designed&Developed by{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://bekzotovich.uz/"
                >
                  Bekzotovich
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
