import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  orderBy,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import { IoAdd } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { AiTwotoneDelete } from "react-icons/ai";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDrag, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AutoTodo from "./AutoTodo";
import { MdOutlineDragIndicator } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import Draggable from "react-draggable";
import { RiDragMoveFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";

const Todo = ({ todoItem, onEdit, onDelete, moveTodo, findTodo }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "TODO",
    item: { id: todoItem.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "TODO",
    hover: (item) => {
      if (item.id !== todoItem.id) {
        moveTodo(item.id, todoItem.id);
        item.id = todoItem.id;
      }
    },
  });

  const opacity = isDragging ? 0.5 : 1;

  const [isEditing, setIsEditing] = useState(false);
  const [editedTodo, setEditedTodo] = useState(todoItem.todo);
  const [editedDescription, setEditedDescription] = useState(
    todoItem.description
  );
  const [editedTimer, setEditedTimer] = useState(todoItem.timer);

  const handleSave = () => {
    onEdit(todoItem.id, editedTodo, editedDescription, editedTimer);
    setIsEditing(false);
  };

  return (
    <>
      <tr ref={(node) => drag(drop(node))} style={{ opacity }}>
        <td>
          <MdOutlineDragIndicator />
        </td>
        <td className="text-sm">
          {isEditing ? (
            <input
              type="text"
              value={editedTodo}
              onChange={(e) => setEditedTodo(e.target.value)}
            />
          ) : (
            todoItem.todo
          )}
        </td>
        <td className="text-sm">
          {isEditing ? (
            <input
              type="text"
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
            />
          ) : (
            todoItem.description
          )}
        </td>
        <td className=" text-sm">
          {isEditing ? (
            <input
              type="time"
              value={editedTimer}
              onChange={(e) => setEditedTimer(e.target.value)}
            />
          ) : (
            todoItem.timer
          )}
        </td>
        <td className="p-3">
          {isEditing ? (
            <button onClick={handleSave} className="text-2xl m-1 p-1">
              <IoMdAdd />
            </button>
          ) : (
            <>
              <button
                onClick={() => setIsEditing(true)}
                className="text-2xl m-1 p-1"
              >
                <CiEdit />
              </button>
              <button
                onClick={() => onDelete(todoItem.id)}
                className="text-2xl m-1 p-1"
              >
                <AiTwotoneDelete />
              </button>
            </>
          )}
        </td>
      </tr>
    </>
  );
};

const UserToDo = ({ onClose }) => {
  const [user] = useAuthState(auth);
  const userUID = user ? user.uid : null;

  const [todo, setTodo] = useState("");
  const [description, setDescription] = useState("");
  const [timer, setTimer] = useState("");
  const [todos, setTodos] = useState([]);
  const [autoTodos, setAutoTodos] = useState([
    { todo: "Go to work", description: "Work-related tasks", timer: "08:00" },
    { todo: "Go to school", description: "Study for exams", timer: "10:00" },
    { todo: "Pray", description: "Take a moment for prayer", timer: "12:00" },
    { todo: "Exercise", description: "Physical activity", timer: "15:00" },
  ]);

  useEffect(() => {
    const fetchTodos = async () => {
      if (userUID) {
        const userTodosQuery = query(
          collection(db, `users/${userUID}/todos`),
          orderBy("timestamp")
        );

        const unsubscribe = onSnapshot(userTodosQuery, (querySnapshot) => {
          const todosData = [];
          querySnapshot.forEach((doc) => {
            todosData.push({ id: doc.id, ...doc.data() });
          });
          setTodos(todosData);
        });

        return () => unsubscribe();
      }
    };

    fetchTodos();
  }, [userUID]);

  const handleAddTodo = async (e) => {
    e.preventDefault();

    if (userUID && todo) {
      try {
        await addDoc(collection(db, `users/${userUID}/todos`), {
          userId: userUID,
          todo,
          description,
          timer,
          timestamp: new Date(),
        });
        setTodo("");
        setDescription("");
        setTimer("");
        const newAutoTodos = autoTodos.filter(
          (autoTodo) => autoTodo.todo !== todo
        );
        setAutoTodos(newAutoTodos);
      } catch (error) {
        console.error("Error adding todo:", error.message);
      }
    }
  };

  const handleDeleteTodo = async (id) => {
    try {
      await deleteDoc(doc(db, `users/${userUID}/todos`, id));
      setTodos((prevTodos) => prevTodos.filter((todo) => todo.id !== id));
    } catch (error) {
      console.error("Error deleting todo:", error.message);
    }
  };

  const handleEditTodo = async (id, newTodoText, newDescription, newTimer) => {
    try {
      await updateDoc(doc(db, `users/${userUID}/todos`, id), {
        todo: newTodoText,
        description: newDescription,
        timer: newTimer,
      });
      setTodos((prevTodos) =>
        prevTodos.map((todo) =>
          todo.id === id
            ? {
                ...todo,
                todo: newTodoText,
                description: newDescription,
                timer: newTimer,
              }
            : todo
        )
      );
    } catch (error) {
      console.error("Error editing todo:", error.message);
    }
  };

  const handleRemoveAutoTodo = async (autoTodo) => {
    await addTodoFromAuto(autoTodo);
    const newAutoTodos = autoTodos.filter(
      (todo) => todo.todo !== autoTodo.todo
    );
    setAutoTodos(newAutoTodos);
  };

  const addTodoFromAuto = async (autoTodo) => {
    if (userUID) {
      try {
        await addDoc(collection(db, `users/${userUID}/todos`), {
          userId: userUID,
          todo: autoTodo.todo,
          description: autoTodo.description,
          timer: autoTodo.timer,
          timestamp: new Date(),
        });
      } catch (error) {
        console.error("Error adding todo:", error.message);
      }
    }
  };

  const moveTodo = (dragId, hoverId) => {
    const dragIndex = todos.findIndex((todo) => todo.id === dragId);
    const hoverIndex = todos.findIndex((todo) => todo.id === hoverId);

    const newTodos = [...todos];
    newTodos.splice(hoverIndex, 0, newTodos.splice(dragIndex, 1)[0]);

    setTodos(newTodos);
  };

  const draggableRef = useRef(null);

  return (
    <Draggable nodeRef={draggableRef}>
      <div
        className="backdrop-blur-sm z-50 rounded-md absolute top-[50%] left-[50%] translate-y-[50%] translate-x-[50%]"
        ref={draggableRef}
      >
        <div className="border border-gray-300 border-dashed rounded-md dark:bg-dark bg-default flex items-center justify-center flex-col transition-colors">
          <div className="h-[30px] flex items-center justify-center cursor-move">
            <span className="dark:text-gray-100 text-default">
              <RiDragMoveFill />
            </span>
            <button
              onClick={onClose}
              className="absolute right-1 text-lg text-default dark:text-dark flex items-center justify-between"
            >
              <IoMdClose />
            </button>
          </div>
          <DndProvider backend={HTML5Backend}>
            <div className="flex justify-center items-center flex-wrap gap-1 gap-y-1 min-w-[350px] lg:w-auto max-lg:w-[300px]">
              <form
                onSubmit={handleAddTodo}
                className="flex flex-wrap bg-[rgba(255,255,255,0.1)] backdrop-blur-sm"
              >
                <input
                  type="text"
                  placeholder="Write your todo"
                  className="p-1 mx-1 rounded-md text-sm max-lg:my-1  outline-none"
                  value={todo}
                  onChange={(e) => setTodo(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Write your description"
                  className="p-1 mx-1 rounded-md text-sm max-lg:my-1 my-4  outline-none"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <input
                  type="time"
                  className="p-1 mx-1 rounded-md text-sm max-lg:my-1  outline-none"
                  value={timer}
                  onChange={(e) => setTimer(e.target.value)}
                />
                <button
                  type="submit"
                  className="p-3 mx-3 text-sm flex items-center justify-center shadow  rounded-lg  text-default dark:text-dark my-2"
                >
                  <span className="flex items-center justify-center mx-1">
                    <IoAdd />
                  </span>{" "}
                  <span className="flex items-center justify-center">
                    Add Todo
                  </span>
                </button>
              </form>
              {todos.length > 0 ? (
                <table className="my-2 w-full text-default dark:text-dark font-todo">
                  <thead className="">
                    <tr className="">
                      <th>Change</th>
                      <th>Todo</th>
                      <th>Description</th>
                      <th>Time to Done</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="mx-auto">
                    {todos.map((todoItem) => (
                      <Todo
                        key={todoItem.id}
                        todoItem={todoItem}
                        onEdit={handleEditTodo}
                        onDelete={handleDeleteTodo}
                        moveTodo={moveTodo}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="my-2 text-sm text-default dark:text-dark ">
                  {todos.length > 0
                    ? "Uploading your todos"
                    : "No todos yet"}
                </p>
              )}
              <hr className=" w-full mx-2 dark: text-default dark:text-dark" />
              <div className="flex items-start justify-center flex-col font-user">
                {autoTodos.map((autoTodo, index) => (
                  <AutoTodo
                    key={index}
                    username={userUID}
                    autoTodo={autoTodo.todo}
                    autoDescription={autoTodo.description}
                    autoTimer={autoTodo.timer}
                    onRemoveAutoTodo={() => handleRemoveAutoTodo(autoTodo)}
                  />
                ))}
              </div>
            </div>
          </DndProvider>
        </div>
      </div>
    </Draggable>
  );
};

export default UserToDo;
