// LinkSaver.js
import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import Draggable from "react-draggable";
import { RiDragMoveFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { PiLinkSimpleBold } from "react-icons/pi";
import { TiTimes } from "react-icons/ti";

const LinkSaver = ({ user, savedLinks, setSavedLinks, onClose }) => {
  const [linkInput, setLinkInput] = useState("");
  const draggableRef = useRef(null);

  useEffect(() => {
    const fetchSavedLinks = async () => {
      try {
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setSavedLinks(userData.savedLinks || []);
          } else {
            await setDoc(userDocRef, { savedLinks: [] });
          }
        }
      } catch (error) {
        console.error("Error fetching savedLinks from Firebase:", error.message);
      }
    };

    fetchSavedLinks();
  }, [user, setSavedLinks]);

  const onLinkSaveClick = async () => {
    try {
      if (user && linkInput.trim() !== "") {
        const newSavedLinks = [
          {
            link: linkInput,
            timestamp: new Date().toISOString(),
          },
          ...savedLinks.slice(0, 4),
        ];
        setSavedLinks(newSavedLinks);
  
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
          savedLinks: newSavedLinks,
        });
  
        setLinkInput("");
      }
    } catch (error) {
      console.error("Error updating savedLinks in Firebase:", error.message);
    }
  };

  const onDeleteLinkClick = async (timestamp) => {
    try {
      const newSavedLinks = savedLinks.filter((link) => link.timestamp !== timestamp);
      setSavedLinks(newSavedLinks);

      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        savedLinks: newSavedLinks,
      });
    } catch (error) {
      console.error("Error deleting link:", error.message);
    }
  };

  const onLinkClick = (link) => {
    window.open(link, "_blank");
  };

  const onLinkInputChange = (event) => {
    setLinkInput(event.target.value);
  };

  return (
    <Draggable nodeRef={draggableRef}>
      <div className="z-50 rounded-md absolute  top-[30%] left-[30%] translate-y-[10%] translate-x-[40%]" ref={draggableRef}>
        <div className="border border-gray-300 border-dashed rounded-md dark:bg-dark bg-default flex items-center justify-center flex-col transition-colors">
          <div className="h-[30px] flex items-center justify-center cursor-move">
            <span className="dark:text-gray-100 text-default">
              <RiDragMoveFill />
            </span>
            <button onClick={onClose} className="absolute right-1 text-lg text-default dark:text-dark flex items-center justify-between">
              <IoMdClose />
            </button>
          </div>
          <div className="flex items-center justify-center gap-2">
            <input
              type="text"
              value={linkInput}
              onChange={onLinkInputChange}
              placeholder="Enter link and click this button 👉"
              className="w-[200px] text-white text-[0.7rem] outline-none bg-[rgba(255,255,255,0.1)] p-1 rounded-md mx-2 mb-2"
            />
            <button onClick={onLinkSaveClick} className=" text-xl flex items-center justify-center mx-1 -translate-y-1 text-default dark:text-dark">
              <PiLinkSimpleBold />
            </button>
          </div>
          {savedLinks && savedLinks.length > 0 && (
            <div className="mt-4">
              <p className="mx-auto font-user  dark:text-dark text-default text-[0.7rem]">Saved Links:</p>
              <ul className="flex items-start justify-center mb-3 flex-col">
                {savedLinks.map((savedLink) => (
                  <li key={savedLink.timestamp} className="flex items-center justify-between text-sm my-1">
                    <span
                      className=" cursor-pointer underline dark:text-gray-100 text-default"
                      onClick={() => onLinkClick(savedLink.link)}
                    >
                      {savedLink.link}
                    </span>
                    <button onClick={() => onDeleteLinkClick(savedLink.timestamp)} className=" dark:text-dark text-default "><TiTimes /></button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default LinkSaver;
