import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import UserHeader from "./UserHeader";
import Draggable from "react-draggable";
import { IoMdClose } from "react-icons/io";
import UserCourses from "./UserCourses";

const UserInterface = () => {
  const location = useLocation();
  const username = location.state?.username;
  const [loading, setLoading] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const draggableRef = useRef(null);
  const [selectedBgIndex, setSelectedBgIndex] = useState(1);
  const [note, setNote] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleToggleDraggable = () => {
    setIsDraggable(!isDraggable);
  };

  const handleSelectBackground = (index) => {
    setSelectedBgIndex(index);
  };
  const handleDeleteNote = () => {
    setNote(!note);
  };

  if (loading) {
    return (
      <div className="absolute top-0 left-0 w-full h-full backdrop-blur-[2px] flex items-center justify-center bg-[rgba(0,0,0,0.5)] text-white">
        <p>Wait a moment, we are making your User Interface...</p>
      </div>
    );
  }

  if (!username) {
    return (
      <div className="border border-[#45474B] rounded-lg bg-[rgba(0,0,0,0.5)] h-screen min-w-[390px] flex items-center justify-center text-[#F5F7F8] p-5 mx-auto">
        You are not logged in.{" "}
        <Link to="/login" className="underline">
          Login
        </Link>{" "}
        to access this page.
      </div>
    );
  }

  return (
    <div className={`relative bg-image-${selectedBgIndex} overflow-auto`}>
      <div className="w-full h-screen flex p-5">
        <div className="flex flex-col gap-3 items-center mx-auto">
          <div className="w-full">
            <UserHeader
              username={username}
              onSelectBackground={handleSelectBackground}
            />
          </div>

          <button
            className="bg-dark text-dark dark:bg-default dark:text-default p-2 rounded-md text-sm mx-auto"
            onClick={handleToggleDraggable}
          >
            {isDraggable ? "lock draggable" : "unlock draggable"}
          </button>
          <span
            className={
              note
                ? `text-[0.7rem] italic p-2 border rounded-md dark:bg-dark bg-default w-[300px] lg:hidden text-default dark:text-dark flex items-center justify-center flex-col`
                : `hidden`
            }
          >
            Note: Please be aware that certain functions may not be accessible
            in mobile and tablet views. To ensure a smooth experience, we
            recommend logging in from a desktop.
            <button
              className="bg-dark text-dark dark:bg-default dark:text-default p-2 rounded-md text-sm"
              onClick={() => handleDeleteNote()}
            >
              <IoMdClose />
            </button>
          </span>

          <div className="flex flex-col gap-3 max-lg:gap-y-3 max-lg:flex-col w-full min-w-full flex-wrap">
            <Draggable disabled={!isDraggable} nodeRef={draggableRef}>
              <div
                ref={draggableRef}
                className="px-5 py-1 m-2  mx-auto shadow-2xl flex items-center justify-center flex-col resizable-box border border-[#45474B] rounded-lg dark:bg-dark dark:text-dark bg-default  text-default transition-colors"
              >
                <div className="mt-4">Your Saved Courses</div>
                <div className="flex items-center justify-center flex-col  lg:min-w-[520px] min-h-[350px]">
                  <UserCourses username={username} />
                </div>
              </div>
            </Draggable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInterface;
