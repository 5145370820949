import React, { useState, useEffect } from "react";
import {
  Input,
  Tag,
  Card,
  Modal,
  List,
  Button,
  Typography,
  message,
} from "antd";
import ReactPlayer from "react-player";
import coursesData from "./CoursesData";
import { db } from "../../firebase";
import { doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

const CourseList = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState(coursesData);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const auth = getAuth();
  const checkAuthentication = () => {
    const currentUser = auth.currentUser;
    setUser(currentUser);
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value);

    const tags = value.split(" ").filter((tag) => tag.trim() !== "");

    const filtered = coursesData.filter((course) =>
      tags.every((tag) =>
        course.tags.some((courseTag) =>
          courseTag.toLowerCase().includes(tag.toLowerCase())
        )
      )
    );

    setFilteredCourses(filtered);
  };

  const handleTagClick = (tag) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
      setSearchText([...selectedTags, tag].join(" "));
      handleSearch([...selectedTags, tag].join(" "));
    }
  };

  const handleTagClose = (tag) => {
    const updatedTags = selectedTags.filter((t) => t !== tag);
    setSelectedTags(updatedTags);
    setSearchText(updatedTags.join(" "));
    handleSearch(updatedTags.join(" "));
  };

  const handleTitleClick = (course) => {
    setSelectedCourse(course);
    setSelectedVideo(course.videos[0].url);
    setVideoModalVisible(true);
  };

  const handleVideoClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };

  const handleVideoClose = () => {
    setSelectedVideo(null);
    setVideoModalVisible(false);
  };

  const handleAddToCollection = async () => {
    try {
      if (!selectedCourse || !selectedCourse.specialKey || !selectedCourse.id) {
        message.error("Selected course, its specialKey, or id is undefined.");
        return;
      }

      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          await updateDoc(userDocRef, {
            savedCourses: arrayUnion({
              id: selectedCourse.id,
              specialKey: selectedCourse.specialKey,
              title: selectedCourse.title,
            }),
          });

          message.success("Course added to savedCourses!");
        } else {
          message.error("User document does not exist for the given UID.");
        }
      } else {
        const courseToAdd = {
          id: selectedCourse.id,
          specialKey: selectedCourse.specialKey,
          title: selectedCourse.title,
        };

        localStorage.setItem("courseToAdd", JSON.stringify(courseToAdd));
        console.log("User not logged in. Redirecting to login page...");
        navigate("/signup", { state: { selectedCourse: selectedCourse } });
      }
    } catch (error) {
      message.error("Error adding course to savedCourses:", error);
    }
  };

  return (
    <div className="bg-[#E0F4FF]">
      <div className="p-4 mt-20">
        <Input
          placeholder="Search by hashtag"
          allowClear
          size="large"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            handleSearch(e.target.value);
          }}
          className="mb-4"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {filteredCourses.map((course) => (
            <Card
              key={course.specialKey}
              title={course.title}
              className="mb-4 cursor-pointer"
              onClick={() => handleTitleClick(course)}
            >
              <div className="flex flex-wrap">
                {course.tags.map((tag) => (
                  <Tag
                    key={tag}
                    className="mb-2 cursor-pointer"
                    color={selectedTags.includes(tag) ? "blue" : ""}
                    onClick={() => handleTagClick(tag)}
                    onClose={() => handleTagClose(tag)}
                  >
                    #{tag}
                  </Tag>
                ))}
              </div>
              <div className="flex items-center justify-between mt-4">
                <div>
                  {Array.from({ length: course.rating }, (_, index) => (
                    <span key={index} className="text-yellow-500">
                      ★
                    </span>
                  ))}
                </div>
              </div>
            </Card>
          ))}
          {filteredCourses.length > 20 && (
            <Card
              className="mb-4 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              <Typography as="h3" variant="h5" className="text-center">
                Login for More
              </Typography>
            </Card>
          )}
        </div>
        {selectedCourse && (
          <Modal
            open={videoModalVisible}
            title={selectedCourse.title}
            onCancel={handleVideoClose}
            footer={
              <Button onClick={handleAddToCollection}>
                Add it to my Collection
              </Button>
            }
            width={500}
            centered
          >
            <div className="flex items-center justify-center flex-col gap-4">
              <div className="rounded-md">
                {selectedVideo && (
                  <ReactPlayer
                    url={selectedVideo}
                    controls={true}
                    width={350}
                  />
                )}
              </div>
              <List
                dataSource={selectedCourse.videos.slice(0, 3)}
                renderItem={(video) => (
                  <List.Item
                    key={video.id}
                    onClick={() => handleVideoClick(video.url)}
                    className="cursor-pointer"
                  >
                    {video.title}
                  </List.Item>
                )}
              />
              {selectedCourse.videos.length > 3 && (
                <Typography className="text-center text-gray-500">
                  <Link to="/signup">signup for more lessons</Link>
                </Typography>
              )}
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default CourseList;
