// Welcome.js
import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import { useTranslation } from "react-i18next";
import { GrTask } from "react-icons/gr";
import { FaStar } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { IoMdBook } from "react-icons/io";
import { PiStudentBold } from "react-icons/pi";
import { LuRocket } from "react-icons/lu";
import Footer from "../components/Footer";

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="flex flex-col items-center justify-center min-h-screen bg-[#E0F4FF] dark:bg-[#445069] transition font-sans">
        <div className="bg-white rounded p-4 text-center max-lg:mt-5">
          <h1 className="text-3xl font-semibold mb-4 text-default dark:text-default">
            {t("welcomeMessage")}
          </h1>
          <p className="text-default transition mb-8">
            {t("organizeTasks")}
          </p>

          <button className="bg-slate-500 text-white py-2 px-4 rounded-md hover:bg-slate-600 transition flex items-center justify-center gap-3 mx-auto">
          <LuRocket /><Link to="/signup">{t("signUp")}</Link>
          </button>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 p-4 mt-5">
          <div className="bg-green-200 p-4 rounded-md shadow-md text-center relative z-10">
            <h2 className="text-lg font-semibold mb-2 dark:text-gray-600">
              {t("taskManagement")}
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[3rem] text-[#F5F7F8] opacity-70 -z-10">
                <GrTask />
              </span>
            </h2>
            <p className="text-default transition">
              {t("taskManagementDescription")}
            </p>
          </div>
          <div className="bg-yellow-200 p-4 rounded-md shadow-md text-center relative z-10">
            <h2 className="text-lg font-semibold mb-2 dark:text-gray-600">
              {t("prioritySetting")}
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[3rem] text-[#F5F7F8] opacity-70 -z-10">
                <FaStar />
              </span>
            </h2>
            <p className="text-default transition">
              {t("prioritySettingDescription")}
            </p>
          </div>

          <div className="bg-pink-200 p-4 rounded-md shadow-md text-center relative z-10">
            <h2 className="text-lg font-semibold mb-2 dark:text-gray-600">
              {t("reminders")}
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[3rem] text-[#F5F7F8] opacity-70 -z-10">
                <FaBell />
              </span>
            </h2>
            <p className="text-default transition">
              {t("remindersDescription")}
            </p>
          </div>

          <div className="bg-blue-200 p-4 rounded-md shadow-md text-center relative z-10">
            <h2 className="text-lg font-semibold mb-2 dark:text-gray-600">
              {t("weather")}
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[3rem] text-[#F5F7F8] opacity-70 -z-10">
                <FaRegClock />
              </span>
            </h2>
            <p className="text-default transition">
              {t("weatherDescription")}
            </p>
          </div>

          <div className="bg-purple-200 p-4 rounded-md shadow-md text-center relative z-10">
            <h2 className="text-lg font-semibold mb-2 dark:text-gray-600">
              {t("bookTracker")}
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[3rem] text-[#F5F7F8] opacity-70 -z-10">
                <IoMdBook />
              </span>
            </h2>
            <p className="text-default transition">
              {t("bookTrackerDescription")}
            </p>
          </div>
          <div className="bg-orange-200 p-4 rounded-md shadow-md text-center relative z-10">
            <h2 className="text-lg font-semibold mb-2 dark:text-gray-600">
              {t("course")}
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[3rem] text-[#F5F7F8] opacity-70 -z-10">
              <PiStudentBold />
              </span>
            </h2>
            <p className="text-default transition">
              {t("courseDescription")}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Welcome;
