import React, { useState } from "react";
//import { FaGoogle } from "react-icons/fa";
import {
  //GoogleAuthProvider,
  // signInWithRedirect,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  doc,
  setDoc,
  //getDoc,
  updateDoc,
  arrayUnion,
  collection,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import { message } from "antd";

import { auth, db } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/header";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [city, setCity] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validateUsername = (inputUsername) => {
    const usernameRegex = /^[a-zA-Z0-9_]+$/;
    return usernameRegex.test(inputUsername);
  };

  const checkUsernameAvailability = async (inputUsername) => {
    try {
      const userQuerySnapshot = await getDocs(
        query(collection(db, "users"), where("username", "==", inputUsername))
      );

      return userQuerySnapshot.empty;
    } catch (error) {
      console.error("Error checking username availability:", error.message);
      return false;
    }
  };

  const handleSignup = async (e) => {
    try {
      setError("");
      e.preventDefault();

      if (!validateUsername(username)) {
        message.error(
          "Invalid username. Please use only letters, numbers, and underscores."
        );
        return;
      }
      const isUsernameAvailable = await checkUsernameAvailability(username);

      if (!isUsernameAvailable) {
        message.error(
          "Username is already in use. Please choose a different username."
        );
        return;
      }

      if (username.length > 10) {
        message.error("Username should not have more than 10 characters.");
        return;
      }
      const isGoogleSignup = localStorage.getItem("isGoogleSignup");

      if (isGoogleSignup) {
        localStorage.removeItem("isGoogleSignup");

        const userDocRef = doc(db, "users", auth.currentUser.uid);
        const courseToAddString = localStorage.getItem("courseToAdd");

        if (courseToAddString) {
          const courseToAdd = JSON.parse(courseToAddString);
          if (courseToAdd) {
            await updateDoc(userDocRef, {
              savedCourses: arrayUnion(courseToAdd),
            });
            localStorage.removeItem("courseToAdd");
          } else {
            console.error("Course ID is undefined");
          }
        }

        navigate("/user");
        return;
      }
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const uid = userCredential.user.uid;
      const userDocRef = doc(db, "users", uid);
      await setDoc(userDocRef, {
        username: username,
        email: email,
        city: city,
      });
      const courseToAddString = localStorage.getItem("courseToAdd");

      if (courseToAddString) {
        const courseToAdd = JSON.parse(courseToAddString);
        if (courseToAdd) {
          await updateDoc(userDocRef, {
            savedCourses: arrayUnion(courseToAdd),
          });
          localStorage.removeItem("courseToAdd");
        } else {
          console.error("Course ID is undefined");
        }
      }

      message.success("User registration successful!");
      navigate("/user", { state: { username: username } });
    } catch (error) {
      console.error("Signup Error:", error.message);
      setError(error.message);
      message.error(`Signup Error: ${error.message}`);
    }
  };

  // const googleSignUp = async () => {
  //   try {
  //     const provider = new GoogleAuthProvider();
  //     const userCredential = await signInWithRedirect(auth, provider);
  //     localStorage.setItem("isGoogleSignup", "true");

  //     const uid = userCredential.user.uid;
  //     const userDocRef = doc(db, "users", uid);
  //     const userDocSnapshot = await getDoc(userDocRef);

  //     if (userDocSnapshot.exists()) {
  //      
  //       const username = userDocSnapshot.data().username;
  //       navigate("/user", { state: { username } });
  //     } else {
  //       
  //       const googleUsername =
  //         userCredential.additionalUserInfo?.profile?.given_name ||
  //         userCredential.user.displayName ||
  //         "GoogleUser";

  //       let suffix = 1;
  //       while (await checkUsernameAvailability(googleUsername + suffix)) {
  //         suffix++;
  //       }

  //       const finalGoogleUsername = googleUsername + suffix;

  //       const randomPassword = generateRandomPassword();
  //       await setDoc(userDocRef, {
  //         username: finalGoogleUsername,
  //         email: userCredential.user.email,
  //         password: randomPassword,
  //         savedCourses: [],
  //       });

  //       console.log("New user registered with Google:", userCredential);
  //       navigate("/user", { state: { username: finalGoogleUsername } });
  //     }
  //   } catch (error) {
  //     setError(`Google Sign-Up Error: ${error.message}`);
  //     message.error(`Google Sign-Up Error: ${error.message}`);
  //   }
  // };

  // const generateRandomPassword = () => {
  //   const characters =
  //     "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  //   const passwordLength = 8;
  //   let password = "";

  //   for (let i = 0; i < passwordLength; i++) {
  //     const randomIndex = Math.floor(Math.random() * characters.length);
  //     password += characters.charAt(randomIndex);
  //   }

  //   return password;
  // };

  return (
    <>
      <Header />
   
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-[#E0F4FF] dark:bg-[#445069] text-default dark:text-dark h-screen">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <span className="text-3xl  font-semibold">MindMingle</span>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight ">
          Sign up for your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSignup}>
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-6 "
            >
              Username
            </label>
            <div className="mt-2">
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="block w-full rounded-md border-0 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 "
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full rounded-md border-0 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 "
            >
              Password
            </label>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border-0 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-500 sm:text-sm sm:leading-6"
                autoComplete="current-password"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="city"
              className="block text-sm font-medium leading-6 "
            >
              City(optional)
            </label>
            <div className="mt-2">
              <input
                id="city"
                name="city"
                type="text"
                autoComplete="city"
                placeholder="Enter your city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="block w-full rounded-md border-0 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
            >
              Sign up
            </button>
          </div>
        </form>
        {/* <button
          onClick={googleSignUp}
          className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded mt-4 hover:bg-red-600"
        >
          <FaGoogle className="mr-2" />
          Sign up with Google
        </button> */}

        <p className="mt-2 text-center text-sm">
          Already have an account?{" "}
          <Link to="/login" className="underline">
            Login
          </Link>
        </p>
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
    </div>
    </>
  );
};

export default Signup;
