import React, { useState, useEffect } from "react";
import DarkModeToggle from "./darkModeToggle";
import LanguageChanger from "./LanguageChanger";
import { useTranslation } from "react-i18next";
import { Button, IconButton, MobileNav } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { Navbar, Typography,  } from "@material-tailwind/react";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";


const Header = () => {
  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [openNav, setOpenNav] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setIsScrolled(scrollPosition > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const NavLink = ({ to, label }) => (
    <Link
      to={to}
      className="text-default dark:text-dark transition duration-300"
    >
      {label}
    </Link>
  );

  const navList = (
    <ul className="flex items-center justify-center flex-wrap gap-5 max-lg:gap-y-3 mx-auto my-auto uppercase font-heading">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="flex items-center gap-x-2 p-1 font-medium"
      >
        <NavLink to={"/"} label={t("Home")} />
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="flex items-center gap-x-2 p-1 font-medium"
      >
        <NavLink to={"/courses"} label={t("Courses")} />
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="flex items-center gap-x-2 p-1 font-medium"
      >
        <NavLink to={"/careers"} label={t("Careers")} />
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="flex items-center gap-x-2 p-1 font-medium"
      >
        <NavLink to={"/contact"} label={t("Contact")} />
      </Typography>
    </ul>
  );

  return (
    <>
      <Navbar
        className={`bg-default dark:bg-[rgba(0,0,0,0.7)] backdrop-blur-lg text-default dark:text-dark w-full border-t-0 border-b-gray-300  ${
          isScrolled ? "sticky top-0 shadow-md z-50" : ""
        }`}
      >
        <div className="w-full flex items-center justify-between p-4 flex-wrap">
          <Typography
            as="a"
            href="#"
            className="mx-auto my-auto max-lg:my-3 text-2xl"
          >
            MindMingle
          </Typography>
          <div className="hidden lg:block">{navList}</div>
          <div className="flex items-center justify-center gap-3 mx-auto my-auto">
            <div className="-translate-y-1">
              <DarkModeToggle />
            </div>
            <LanguageChanger />
          </div>
          <div className="max-lg:hidden mx-auto my-auto flex items-center justify-center gap-x-3">
            <button className="uppercase">
              <NavLink to={"/login"} label={t("login")}>
                Log In
              </NavLink>
            </button>
            <button className="uppercase flex items-center justify-center gap-3">
              <NavLink to={"/signup"} label={t("register")}>
                Sign Up
              </NavLink>
             <span className="rotate-90"><MdOutlineKeyboardDoubleArrowUp className="animate-bounce -translate-y-1" /></span>
            </button>
          </div>
          <IconButton
            variant="text"
            className="lg:hidden text-default dark:text-dark max-lg:mr-5 mx-auto my-auto max-lg:my-3"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
      </Navbar>
      <MobileNav open={openNav} className="bg-default dark:bg-dark lg:hidden">
        <div className="container mx-auto bg-default dark:bg-dark text-default dark:text-dark h-[200px] flex items-center justify-center flex-col lg:hidden">
          {navList}
          <div className="flex items-center gap-x-1 my-5">
            <Button fullWidth variant="text" className=" text-default dark:text-dark">
              <NavLink to={"/login"} label={t("login")}>
                Log In
              </NavLink>
            </Button>
            <Button fullWidth variant="text" className="text-default dark:text-dark">
              <NavLink to={"/signup"} label={t("register")}>
                Sign Up
              </NavLink>
            </Button>
          </div>
        </div>
      </MobileNav>
      <div
        className={`w-full h-${isScrolled ? "14" : "0"} transition-all`}
      ></div>
    </>
  );
};

export default Header;
