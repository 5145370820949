import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Menu, Space } from "antd";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

import "flag-icons/css/flag-icons.min.css";

const LanguageChanger = () => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const changeLanguage = async (lng) => {
    setLoading(true);
    await i18n.changeLanguage(lng);
    setLoading(false);
  };

  const languageMenu = (
    <Menu>
      <Menu.Item key="en" onClick={() => changeLanguage("en")}>
        <Space>
          <span className={`fi fi-us`} />
          <span>EN'us'</span>
        </Space>
      </Menu.Item>
      <Menu.Item key="ru" onClick={() => changeLanguage("ru")}>
        <Space>
          <span className={`fi fi-ru`} />
          <span>RU</span>
        </Space>
      </Menu.Item>
      <Menu.Item key="uz" onClick={() => changeLanguage("uz")}>
        <Space>
          <span className={`fi fi-uz`} />
          <span>UZ</span>
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={`flex items-center space-x-2 ${
        loading ? "opacity-50 cursor-not-allowed" : ""
      }`}
    >
      <button
        className={`text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-white py-2 px-1 rounded-md transition ${
          loading ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={loading}
        onClick={() => changeLanguage(i18n.language)}
      >
        {i18n.language.toUpperCase()}
      </button>
      <Dropdown overlay={languageMenu} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()} className="text-xl">
          <MdOutlineKeyboardArrowDown />
        </a>
      </Dropdown>
    </div>
  );
};

export default LanguageChanger;
