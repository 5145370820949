// Careers.js
import React from "react";
import Header from "../components/header";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";

const Careers = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="flex flex-col items-center justify-center min-h-screen bg-[#E0F4FF] dark:bg-[#445069] transition">
        <div className="bg-white rounded p-4 text-center">
          <h1 className="text-3xl font-semibold mb-4 dark:text-gray-700 text-gray-800">
            {t("careersPageTitle")}
          </h1>
          <p className="text-gray-600 dark:text-gray-500 transition mb-8">
            {t("careersPageSubtitle")}
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 p-4 mt-5">
          <div className="bg-green-200 p-4 rounded-md shadow-md text-center relative z-10">
            <h2 className="text-lg font-semibold mb-2 dark:text-gray-700">
              {t("jobOpening1")}
            </h2>
            <p className="text-gray-600 dark:text-gray-500 transition">
              {t("jobDescription1")}
            </p>
          </div>
          <div className="bg-yellow-200 p-4 rounded-md shadow-md text-center relative z-10">
            <h2 className="text-lg font-semibold mb-2 dark:text-gray-700">
              {t("jobOpening2")}
            </h2>
            <p className="text-gray-600 dark:text-gray-500 transition">
              {t("jobDescription2")}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Careers;
