import React, { useState, useEffect } from "react";
import { Card, List, Spin, message } from "antd";
import ReactPlayer from "react-player";
import { db, auth } from "../firebase";
import {
  doc,
  onSnapshot,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import coursesData from "../components/courses/CoursesData";
import { GoSignIn } from "react-icons/go";
import { AiTwotoneDelete } from "react-icons/ai";
import { MdSave } from "react-icons/md";
import { Link } from "react-router-dom";


const UserCourses = () => {
  const [userCourses, setUserCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  let unsubscribeCourses;

  useEffect(() => {
    const fetchUserCourses = async (user) => {
      try {
        if (!user) {
          setLoading(false);
          setUserCourses([]);
          return;
        }

        const userDocRef = doc(db, "users", user.uid);
        unsubscribeCourses = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            const userSavedCourses = doc.data().savedCourses || [];
            // console.log("userSavedCourses:", userSavedCourses);

            const matchingCourses = userSavedCourses.map((savedCourse) =>
              coursesData.find(
                (course) => course.specialKey === savedCourse.specialKey
              )
            );
            // console.log("matchingCourses:", matchingCourses);

            setUserCourses(matchingCourses.filter(Boolean));
          }
          setLoading(false);
        });
      } catch (error) {
        console.error("Error fetching courses:", error);
        setLoading(false);
        message.error("Error fetching courses. Please try again later.");
      }
    };

    const handleAuthStateChange = (user) => {
      fetchUserCourses(user);
    };

    const unsubscribeAuthStateChange = auth.onAuthStateChanged(
      handleAuthStateChange
    );

    return () => {
      if (unsubscribeCourses) {
        unsubscribeCourses();
      }
      unsubscribeAuthStateChange();
    };
  }, []);

  const handleRemoveCourse = async (courseId) => {
    try {
      setLoading(true);
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const savedCourses = userDoc.data().savedCourses || [];
      const indexToRemove = savedCourses.findIndex(
        (course) => course.id === courseId
      );
      if (indexToRemove !== -1) {
        savedCourses.splice(indexToRemove, 1);
        await updateDoc(userDocRef, {
          savedCourses: savedCourses,
        });

        console.log("Course removed successfully");
        setUserCourses(savedCourses);
        message.success("Course removed successfully!");
      } else {
        console.warn("Course not found with id:", courseId);
        message.warning("Course not found.");
      }
    } catch (error) {
      console.error("Error removing course:", error);
      message.error("Error removing course. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleTitleClick = async (course) => {
    try {
      setLoading(true);
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const savedCourses = userDoc.data().savedCourses || [];
      const indexToUpdate = savedCourses.findIndex((c) => c.id === course.id);
      const updatedCourse = { ...savedCourses[indexToUpdate] };
      if (!updatedCourse.viewedVideo) {
        updatedCourse.viewedVideo = course.videos[0].url;
      }
      savedCourses[indexToUpdate] = updatedCourse;
      const matchingExampleCourse = coursesData.find(
        (exampleCourse) => exampleCourse.specialKey === course.specialKey
      );
      updatedCourse.videos = matchingExampleCourse.videos;
      await updateDoc(userDocRef, {
        savedCourses: savedCourses,
      });

      console.log("Viewed video initialized successfully");
      setUserCourses(savedCourses);
      setSelectedCourse(updatedCourse);
      setSelectedVideo(updatedCourse.viewedVideo || course.videos[0].url);
      message.success("Viewed video initialized successfully!");
    } catch (error) {
      console.error("Error initializing viewed video:", error);
      message.error("Error initializing viewed video. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleVideoClick = async (videoUrl) => {
    try {
      setLoading(true);
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const savedCourses = userDoc.data().savedCourses || [];
      const index = savedCourses.findIndex(
        (course) => course.id === selectedCourse.id
      );

      if (index !== -1) {
        savedCourses[index].viewedVideo = videoUrl;
        await updateDoc(userDocRef, {
          savedCourses: savedCourses,
        });

        console.log("Viewed video updated successfully");
        setUserCourses(savedCourses);
        setSelectedVideo(videoUrl);
        message.success("Viewed video updated successfully!");
      }
    } catch (error) {
      console.error("Error updating viewed video:", error);
      message.error("Error updating viewed video. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleVideoClose = () => {
    setSelectedCourse(null);
    setSelectedVideo(null);
  };

  return (
    <div>
      <div className="p-4 mt-5 text-default dark:text-dark">
        {loading ? (
          <Spin size="large" />
        ) : !selectedCourse && !selectedVideo && userCourses.length === 0 ? (
          <p>
            You don't have any courses yet. Visit the Courses page to add a
            course.
            <Link
              to="/courses"
              className="font-semibold leading-6 text-default dark:text-dark"
            >
              Courses
            </Link>
          </p>
        ) : (
          <div className="flex items-center justify-center gap-4 relative flex-wrap">
            {selectedVideo && (
              <div className="rounded-md">
                <ReactPlayer
                  url={selectedVideo}
                  controls={true}
                  width={350}
                  style={{ borderRadius: "10px" }}
                />
              </div>
            )}
            <div className="my-auto h-[350px] overflow-y-auto">
              {!selectedCourse && !selectedVideo ? (
                <div className="flex items-center justify-center flex-wrap">
                  {userCourses.map((course) => (
                    <Card
                      key={course.specialKey}
                      title={course.title}
                      className="mb-4 cursor-pointer mx-2 my-2"
                    >
                      <div className="flex items-center justify-center gap-3">
                        <button
                          onClick={() => handleTitleClick(course)}
                          className="flex items-center justify-center gap-2"
                        >
                          <span className="flex items-center justify-center">
                            <GoSignIn />
                          </span>
                          <span className="flex items-center justify-center">
                            Enter Course
                          </span>
                        </button>
                        <button
                          onClick={() => handleRemoveCourse(course.id)}
                          className="flex items-center justify-center gap-2"
                        >
                          <span className="flex items-center justify-center font-xl">
                            <AiTwotoneDelete />
                          </span>{" "}
                          <span className="flex items-center justify-center">
                            Remove Course
                          </span>
                        </button>
                      </div>
                    </Card>
                  ))}
                </div>
              ) : (
                <>
                  <List
                    dataSource={selectedCourse?.videos || []}
                    renderItem={(video) => (
                      <List.Item
                        key={video.id}
                        onClick={() => handleVideoClick(video.url)}
                        className={`cursor-pointer text-default dark:text-dark ${
                          video.url === selectedVideo ? "font-bold" : ""
                        }`}
                      >
                        {video.title}
                      </List.Item>
                    )}
                  />
                  <button
                    onClick={handleVideoClose}
                    className="mx-auto flex items-center justify-center gap-2 absolute top-0 right-5 p-2 rounded-md bg-default dark:bg-dark"
                  >
                    <span className="flex items-center justify-center">
                      Save and Quit{" "}
                    </span>
                    <span className="flex items-center justify-center">
                      <MdSave />
                    </span>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserCourses;
