import React, { useState, useEffect, useRef } from "react";
import YouTube from "react-youtube";
import Draggable from "react-draggable";
import DarkModeToggle from "../components/darkModeToggle";
import { IoMdClose } from "react-icons/io";
import { RiDragMoveFill } from "react-icons/ri";
import { updateDoc, doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signOut } from "firebase/auth";
import LastViewed from "./LastViewed";
import { HiOutlinePlayPause } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import {
  MdOutlineSettings,
  MdLogout,
  MdFullscreen,
  MdFullscreenExit,
  MdOutlineCalendarToday,
  MdNotes,
} from "react-icons/md";
import { LuGalleryVertical } from "react-icons/lu";
import { DatePicker } from "antd";
import { PiLinkSimpleBold } from "react-icons/pi";
import LinkSaver from "./LinkSaver";
import NotesComponent from "./NotesComponent";
import UserSettings from "./UserSettings";
import { PiTimerBold } from "react-icons/pi";
import PomodoroTimer from "./PomodoroTimer";
import Image1 from "../assets/bg1.jpg";
import Image2 from "../assets/bg2.jpg";
import Image3 from "../assets/bg6.jpg";
import Weather from "./Weather";
import { LiaCloudSunRainSolid } from "react-icons/lia";
import { LuListTodo } from "react-icons/lu";
import { LuMoonStar } from "react-icons/lu";
import Prays from "./Prays";
import UserToDo from "./userTodo";

const UserHeader = ({ username, onSelectBackground }) => {
  const draggableRef = useRef(null);
  const auth = getAuth();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(new Date());
  const [videoLink, setVideoLink] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [lastViewed, setLastViewed] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showLinkSaver, setShowLinkSaver] = useState(false);
  const [savedLinks, setSavedLinks] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const [savedNotes, setSavedNotes] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [showPomodoro, setShowPomodoro] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [showWeather, setShowWeather] = useState(false);
  const [showPrays, setShowPrays] = useState(false);
  const [showTodos, setShowTodos] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchLastViewed = async () => {
      try {
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setLastViewed(userData.lastViewed || []);
          } else {
            await setDoc(userDocRef, { lastViewed: [] });
          }
        }
      } catch (error) {
        console.error(
          "Error fetching lastViewed from Firebase:",
          error.message
        );
      }
    };

    fetchLastViewed();
  }, [user]);

  const onSignOutClick = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };

  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  // You Tube Video

  const onPlayButtonClick = async () => {
    try {
      const videoId = extractVideoId(videoLink);

      if (videoId) {
        const apiKey = "AIzaSyBDhk30OaQLi8XKUv8ko6JrHflU7yXF2qg";
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet,contentDetails`
        );
        const data = await response.json();

        if (data.items.length > 0) {
          setShowVideo(true);
          const newLastViewed = [
            {
              id: videoId,
              name: data.items[0].snippet.title,
              idx: Date.now().toString(),
            },
            ...lastViewed.slice(0, 4),
          ];
          setLastViewed(newLastViewed);

          try {
            if (user) {
              const userDocRef = doc(db, "users", user.uid);
              await updateDoc(userDocRef, {
                lastViewed: newLastViewed,
              });
            }
          } catch (error) {
            console.error(
              "Error updating lastViewed in Firebase:",
              error.message
            );
          }
        } else {
          console.error("Invalid YouTube video ID");
        }
      } else {
        console.error("No valid YouTube video ID found");
      }
    } catch (error) {
      console.error("Error fetching video details", error);
    }
  };

  const onStopButtonClick = () => {
    setShowVideo(false);
    setVideoLink("");
  };

  const onDeleteLastViewed = async (idx) => {
    const newLastViewed = lastViewed.filter((item) => item.idx !== idx);
    setLastViewed(newLastViewed);

    try {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
          lastViewed: newLastViewed,
        });

        const videoDocRef = doc(db, "videos", idx);
        await deleteDoc(videoDocRef);
      }
    } catch (error) {
      console.error("Error updating lastViewed in Firebase:", error.message);
    }
  };

  const onNameClick = (videoId) => {
    setVideoLink(`https://www.youtube.com/watch?v=${videoId}`);
    onPlayButtonClick();
  };

  const onVideoLinkChange = (event) => {
    setVideoLink(event.target.value);
  };

  const extractVideoId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
    );
    return match ? match[1] : null;
  };

  const videoControl = (
    <div className="flex flex-col max-lg:gap-y-1 lg:mx-5 p-1">
      <div className="flex items-center justify-center max-lg:gap-y-1">
        <input
          type="text"
          value={videoLink}
          onChange={onVideoLinkChange}
          placeholder="Enter YouTube link and click this button 👉"
          className="w-[250px] text-white text-[0.7rem] outline-none bg-[rgba(255,255,255,0.1)] p-1 rounded-md mr-5 hover:scale-110 focus:scale-110"
        />
        <button
          onClick={onPlayButtonClick}
          className="text-xl flex items-center justify-center hover:scale-110"
        >
          <HiOutlinePlayPause />
        </button>
      </div>
      {showVideo && <button onClick={onStopButtonClick}>Stop</button>}
    </div>
  );

  const stopVideoButton = (
    <div className="absolute right-1 text-lg dark:bg-dark dark:text-dark flex items-center justify-between">
      <button onClick={onStopButtonClick}>
        <IoMdClose />
      </button>
    </div>
  );

  const videoComponent = (
    <Draggable nodeRef={draggableRef}>
      <div className=" z-50 rounded-md absolute" ref={draggableRef}>
        {showVideo && (
          <div className="border border-gray-300 border-dashed rounded-md dark:bg-dark  bg-default  flex items-center justify-center flex-col">
            <div className="h-[30px] flex items-center justify-center cursor-move">
              <span className="dark:text-gray-100 dark:bg-dark text-default">
                <RiDragMoveFill />
              </span>
              {stopVideoButton}
            </div>
            <div className="mx-auto rounded-md">
              <YouTube
                videoId={extractVideoId(videoLink)}
                opts={{
                  width: "300",
                  height: "200",
                  playerVars: { cc_load_policy: 0 },
                }}
              />
            </div>

            <LastViewed
              lastViewed={lastViewed}
              onDeleteLastViewed={onDeleteLastViewed}
              onNameClick={onNameClick}
            />
          </div>
        )}
      </div>
    </Draggable>
  );

  // Calendar Component

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleCalendarChange = (value) => {
    console.log("Selected Date:", value);
  };

  const calendarComponent = (
    <Draggable nodeRef={draggableRef}>
      <div
        className=" z-50 rounded-md absolute  top-[20%] left-[20%] translate-y-[20%] translate-x-[20%]"
        ref={draggableRef}
      >
        <div className="border border-gray-300 border-dashed rounded-md dark:bg-dark  bg-default  flex items-center justify-center flex-col">
          <div className="h-[30px] flex items-center justify-center cursor-move">
            <span className="dark:text-gray-100 dark:bg-dark">
              <RiDragMoveFill />
            </span>
            <button
              onClick={toggleCalendar}
              className="absolute right-1 text-lg dark:bg-dark dark:text-dark flex items-center justify-between"
            >
              <IoMdClose />
            </button>
          </div>
          <DatePicker
            onChange={handleCalendarChange}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </div>
      </div>
    </Draggable>
  );

  //Full Screen Mode

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
    // Close other components when toggling fullscreen
    setShowCalendar(false);
    setShowPomodoro(false);
    setShowLinkSaver(false);
    setShowNotes(false);
    setShowGallery(false);
    setShowWeather(false);
    setShowPrays(false);
    setShowTodos(false);
  };

  //Links Saver Component

  const linkSaverComponent = (
    <LinkSaver
      user={user}
      savedLinks={savedLinks}
      setSavedLinks={setSavedLinks}
      onClose={() => setShowLinkSaver(false)}
    />
  );
  const toggleLinkSaver = () => {
    setShowLinkSaver(!showLinkSaver);
  };

  // Notes Component

  const toggleNotes = () => {
    setShowNotes(!showNotes);
  };

  const notesComponent = (
    <NotesComponent
      user={user}
      savedNotes={savedNotes}
      setSavedNotes={setSavedNotes}
      onClose={() => setShowNotes(false)}
    />
  );

  // usersetting
  const toggleSettings = () => {
    navigate("/settings");
  };

  //Pomodoro
  const togglePomodoro = () => {
    setShowPomodoro(!showPomodoro);
  };
  const PomodoroTime = <PomodoroTimer onClose={() => setShowPomodoro(false)} />;

  //Gallery
  const GalleryComponent = () => (
    <div className="z-50 absolute top-[9%] right-[23%] translate-y-[10%] translate-x-[23%]">
      <div className="rounded-md w-[300px] flex items-center justify-center top-[20%] left-[20%] translate-y-[20%] translate-x-[20%] p-5 dark:bg-dark bg-default">
        <img
          className="mx-1 rounded-md w-[90px]"
          onClick={img1}
          src={Image1}
          alt="background 1"
        />
        <img
          className="mx-1 rounded-md w-[90px]"
          onClick={img2}
          src={Image2}
          alt="background 1"
        />
        <img
          className="mx-1 rounded-md w-[90px]"
          onClick={img3}
          src={Image3}
          alt="background 1"
        />
      </div>
    </div>
  );
  const img1 = () => {
    onSelectBackground(1);
    setShowGallery(!showGallery);
  };
  const img2 = () => {
    onSelectBackground(2);
    setShowGallery(!showGallery);
  };
  const img3 = () => {
    onSelectBackground(3);
    setShowGallery(!showGallery);
  };
  const toggleGallery = () => {
    setShowGallery(!showGallery);
  };

  const toggleWeather = () => {
    setShowWeather(!showWeather);
    setShowPrays(false);
    setShowTodos(false);
  };

  const togglePrays = () => {
    setShowPrays(!showPrays);
    setShowWeather(false);
    setShowTodos(false);
  };

  const toggleTodos = () => {
    setShowTodos(!showTodos);
    setShowWeather(false);
    setShowPrays(false);
  };

  return (
    <div className=" lg:w-[1300px] h-[50px] flex items-center justify-between max-lg:h-auto max-lg:flex-col max-lg:gap-y-3 px-3 font-user border border-gray-300 rounded-lg bg-dark text-white ">
      <div className="flex items-center justify-center gap-3 max-lg:gap-y-3 text-gray-300 text-[0.7rem] bg-[rgba(255,255,255,0.1)] p-2 mr-5 w-[150px] h-full border-x max-lg:border-none max-lg:mt-2 max-lg:rounded-md">
        <span>welcome</span> <span>{username}</span>
      </div>
      <div className="flex items-center justify-center text-gray-300 text-[0.7rem] bg-[rgba(255,255,255,0.1)] p-2 rounded-md mr-5 w-[150px]">
        <span>⏰ {formattedTime} </span>
      </div>
      {showVideo ? videoComponent : videoControl}
      {showCalendar && calendarComponent}
      {showLinkSaver && linkSaverComponent}
      {showNotes && notesComponent}
      {showSettings && <UserSettings />}
      {showPomodoro && PomodoroTime}
      {showGallery && <GalleryComponent />}
      {showWeather && (
        <Weather username={username} onClose={() => setShowWeather(false)} />
      )}
      {showPrays && <Prays onClose={() => setShowPrays(false)} />}
      {showTodos && <UserToDo onClose={() => setShowTodos(false)} />}
      <div className="mx-3 flex items-center justify-center gap-3 px-3">
          <button onClick={toggleWeather} className="relative group">
            <LiaCloudSunRainSolid />
            <span className="opacity-0 group-hover:opacity-100 absolute mx-auto top-10 left-1/2 transform -translate-x-1/2 bg-[rgba(255,255,255,0.3)] text-white text-sm p-1 rounded-md">
              Weather
            </span>
          </button>
          <button onClick={togglePrays} className="relative group">
            <LuMoonStar />
            <span className="opacity-0 group-hover:opacity-100 absolute mx-auto top-10 left-1/2 transform -translate-x-1/2 bg-[rgba(255,255,255,0.3)] text-white text-sm p-1 rounded-md">
              Prays times
            </span>
          </button>
          <button onClick={toggleTodos} className="relative group">
            <LuListTodo />
            <span className="opacity-0 group-hover:opacity-100 absolute mx-auto top-10 left-1/2 transform -translate-x-1/2 bg-[rgba(255,255,255,0.3)] text-white text-sm p-1 rounded-md">
              To Do
            </span>
          </button>
        </div>
      <div className=" text-white max-lg:mb-2 flex items-center justify-center gap-3 text-lg">
        <span className="-translate-y-1  divide-solid hover:scale-110">
          <DarkModeToggle />
        </span>
        <button onClick={toggleFullscreen} className="relative group">
          <span className="text-xl hover:scale-110">
            {isFullscreen ? <MdFullscreenExit /> : <MdFullscreen />}
          </span>
          <span className="opacity-0 group-hover:opacity-100 absolute mx-auto top-10 left-1/2 transform -translate-x-1/2 bg-[rgba(255,255,255,0.3)] text-white text-sm p-1 rounded-md">
            Full Screen
          </span>
        </button>
        
        <button className="relative group" onClick={toggleGallery}>
          <LuGalleryVertical className=" hover:scale-110" />
          <span className="opacity-0 group-hover:opacity-100 absolute mx-auto top-10 left-1/2 transform -translate-x-1/2 bg-[rgba(255,255,255,0.3)] text-white text-sm p-1 rounded-md">
            Background
          </span>
        </button>
        <button onClick={toggleLinkSaver} className="relative group">
          <PiLinkSimpleBold className=" hover:scale-110" />
          <span className="opacity-0 group-hover:opacity-100 absolute mx-auto top-10 left-1/2 transform -translate-x-1/2 bg-[rgba(255,255,255,0.3)] text-white text-sm p-1 rounded-md">
            Links
          </span>
        </button>
        <button onClick={toggleCalendar} className="relative group">
          <MdOutlineCalendarToday className=" hover:scale-110" />
          <span className="opacity-0 group-hover:opacity-100 absolute mx-auto top-10 left-1/2 transform -translate-x-1/2 bg-[rgba(255,255,255,0.3)] text-white text-sm p-1 rounded-md">
            Reminder
          </span>
        </button>
        <button onClick={toggleNotes} className="relative group">
          <MdNotes className=" hover:scale-110" />
          <span className="opacity-0 group-hover:opacity-100 absolute mx-auto top-10 left-1/2 transform -translate-x-1/2 bg-[rgba(255,255,255,0.3)] text-white text-sm p-1 rounded-md">
            Notes
          </span>
        </button>
        <button onClick={togglePomodoro} className="relative group">
          <PiTimerBold className="hover:scale-110" />
          <span className="opacity-0 group-hover:opacity-100 absolute mx-auto top-10 left-1/2 transform -translate-x-1/2 bg-[rgba(255,255,255,0.3)] text-white text-sm p-1 rounded-md">
            Pomodoro
          </span>
        </button>
        <button onClick={toggleSettings} className=" hover:scale-110">
          <MdOutlineSettings />
        </button>
      </div>
      <div className="mx-1 max-lg:mb-3">
        {" "}
        <button
          onClick={onSignOutClick}
          className="flex items-center justify-center hover:scale-110"
        >
          <MdLogout />
        </button>
      </div>
    </div>
  );
};

export default UserHeader;
