// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Welcome from "./pages/Welcome";
import Login from "./user/login/Login";
import Signup from "./user/signup/signup";
import NotFound from "./components/NotFound";
import UserInterface from "./user/userInterface";
import PasswordReset from "./user/login/PasswordReset";
import UserSettings from "./user/UserSettings";
import "./components/i18n";
import Courses from "./pages/Courses";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";

const App = () => {
  const userEmail = "bekzotovich12@gmail.com";
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/Courses" element={<Courses />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/user/*" element={<UserInterface />} />
          <Route path="/settings" element={<UserSettings />} />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/dashboard"
            element={<Dashboard userEmail={userEmail} />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
