import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import { doc, updateDoc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { RiDragMoveFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { TiTimes } from "react-icons/ti";
import { MdOutlineAddTask } from "react-icons/md";


const NotesComponent = ({ user, savedNotes, setSavedNotes, onClose }) => {
  const [noteInput, setNoteInput] = useState("");
  const [boldSelected, setBoldSelected] = useState(false);
  const [underlineSelected, setUnderlineSelected] = useState(false);
  const [italicSelected, setItalicSelected] = useState(false);

  const draggableRef = useRef(null);

  useEffect(() => {
    const fetchSavedNotes = async () => {
      try {
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setSavedNotes(userData.savedNotes || []);
          } else {
            await setDoc(userDocRef, { savedNotes: [] });
          }
        }
      } catch (error) {
        console.error(
          "Error fetching savedNotes from Firebase:",
          error.message
        );
      }
    };

    fetchSavedNotes();
  }, [user, setSavedNotes]);

  const onNoteSaveClick = async () => {
    try {
      console.log("Inside onNoteSaveClick");

      if (user && noteInput.trim() !== "") {
        console.log("Saving note...");
        const newSavedNotes = [
          {
            note: noteInput,
            timestamp: new Date().toISOString(),
            bold: boldSelected,
            underline: underlineSelected,
            italic: italicSelected,
          },
          ...savedNotes.slice(0, 4),
        ];

        console.log("New Saved Notes:", newSavedNotes);

        setSavedNotes(newSavedNotes);

        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
          savedNotes: newSavedNotes,
        });

        console.log("Saved to Firestore");

        setNoteInput("");
        //onClose();
      }
    } catch (error) {
      console.error("Error updating savedNotes in Firebase:", error.message);
    }
  };

  const onDeleteNoteClick = async (timestamp) => {
    try {
      const newSavedNotes = savedNotes.filter(
        (note) => note.timestamp !== timestamp
      );
      setSavedNotes(newSavedNotes);

      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        savedNotes: newSavedNotes,
      });
    } catch (error) {
      console.error("Error deleting note:", error.message);
    }
  };

  const onNoteClick = (note) => {
    // Handle the action when clicking on a note
  };

  const onNoteInputChange = (event) => {
    setNoteInput(event.target.value);
  };

  const toggleBold = () => {
    setBoldSelected(!boldSelected);
  };

  const toggleUnderline = () => {
    setUnderlineSelected(!underlineSelected);
  };

  const toggleItalic = () => {
    setItalicSelected(!italicSelected);
  };

  return (
    <Draggable nodeRef={draggableRef}>
      <div
        className="backdrop-blur-sm z-50 rounded-md absolute top-[50%] left-[50%] translate-y-[50%] translate-x-[50%]"
        ref={draggableRef}
      >
        <div className="border border-gray-300 border-dashed rounded-md dark:bg-dark bg-default flex items-center justify-center flex-col transition-colors">
          <div className="h-[30px] flex items-center justify-center cursor-move">
            <span className="dark:text-gray-100 text-default">
              <RiDragMoveFill />
            </span>
            <button
              onClick={onClose}
              className="absolute right-1 text-lg text-default   dark:text-dark flex items-center justify-between"
            >
              <IoMdClose />
            </button>
          </div>
          <div className="flex items-center justify-center gap-2">
            <div className="gap-y-2 mx-auto flex flex-col items-center justify-center">
              <div className="flex gap-2 text-default   dark:text-dark">
                <button onClick={toggleBold} className="text-xl font-bold">
                  B
                </button>
                <button onClick={toggleUnderline} className="text-xl underline">
                  U
                </button>
                <button onClick={toggleItalic} className="text-xl italic">
                  I
                </button>
              </div>
              <textarea
                value={noteInput}
                onChange={onNoteInputChange}
                placeholder="Enter your note here..."
                className="w-[150px] h-[100px] text-default   dark:text-dark text-[0.7rem] outline-none bg-[rgba(255,255,255,0.1)] p-1 rounded-md mx-2 mb-2"
                style={{
                  fontWeight: boldSelected ? "bold" : "normal",
                  textDecoration: underlineSelected ? "underline" : "none",
                  fontStyle: italicSelected ? "italic" : "normal",
                }}
              />
            </div>

            <button
              onClick={onNoteSaveClick}
              className=" text-xl flex items-center justify-center mr-2 text-default   dark:text-dark"
            >
              <MdOutlineAddTask />
            </button>
          </div>
          {savedNotes && savedNotes.length > 0 && (
            <div className="mt-4">
              <p className="mx-auto font-user   dark:text-dark text-default text-[0.7rem]">
                Saved Notes:
              </p>
              <ul className="flex items-start justify-center mb-3 flex-col">
                {savedNotes.map((savedNote) => (
                  <li
                    key={savedNote.timestamp}
                    className="flex items-center justify-between text-sm my-1 text-default   dark:text-dark"
                  >
                    <span
                      className=" cursor-pointer underline"
                      onClick={() => onNoteClick(savedNote.note)}
                      style={{
                        fontWeight: savedNote.bold ? "bold" : "normal",
                        textDecoration: savedNote.underline
                          ? "underline"
                          : "none",
                        fontStyle: savedNote.italic ? "italic" : "normal",
                      }}
                    >
                      {savedNote.note}
                    </span>
                    <button
                      onClick={() => onDeleteNoteClick(savedNote.timestamp)}
                      className="  dark:text-dark text-default "
                    >
                      <TiTimes />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default NotesComponent;
