// Weather.js
import React, { useState, useEffect, useRef } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Draggable from "react-draggable";
import { RiDragMoveFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";

const Weather = ({onClose}) => {
  const [user] = useAuthState(auth);
  const userUID = user ? user.uid : null;

  const [city, setCity] = useState("");
  const [weatherData, setWeatherData] = useState(null);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [cityError, setCityError] = useState(false);
  const draggableRef = useRef(null);

  useEffect(() => {
    const fetchCityFromFirestore = async () => {
      try {
        if (userUID) {
          const userDocRef = doc(db, `users/${userUID}`);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            const userCity = userData?.city || "";

            if (userCity) {
              setCity(userCity);
              setShowForm(false);

              // Fetch weather data when city is available
              const weatherApiUrl = `https://api.openweathermap.org/data/2.5/forecast?q=${userCity}&appid=5ffdc66d9eb63ec59f26ed5d004069ba&cnt=7`;

              const response = await fetch(weatherApiUrl);
              const data = await response.json();

              console.log("Weather API response:", data);

              if (response.ok) {
                setWeatherData(data);
                setCityError(false);
              } else {
                setCityError(true);
                setError(`Error: ${data.message}`);
              }
            } else {
              setShowForm(true);
            }
          } else {
            console.error("User document not found for UID:", userUID);
          }
        } else {
          console.error("User not authenticated");
        }
      } catch (error) {
        console.error("Error fetching city from Firestore:", error.message);
        setCityError(true);
      }
    };

    fetchCityFromFirestore();
  }, [userUID]);

  const handleCitySubmit = async (e) => {
    e.preventDefault();

    try {
      const userDocRef = doc(db, `users/${userUID}`);
      await updateDoc(userDocRef, { city });
      setShowForm(false);
      setCityError(false);
    } catch (error) {
      console.error("Error updating user city:", error.message);
    }
  };

  const renderForecast = () => {
    if (cityError) {
      return (
        <div>
          <p className="text-red-500 font-bold">
            There is a problem with your city. We can't find it.
          </p>
          <p>Please enter your city:</p>
          <form onSubmit={handleCitySubmit}>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
            <button type="submit">Save</button>
          </form>
        </div>
      );
    }

    if (error) {
      return (
        <p className="text-green-500 italic">
          We'll display the weather for your city once you refresh this page.
        </p>
      );
    }

    if (!weatherData) {
      return <p className="italic text-gray-700">Loading...</p>;
    }

    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date();

    const forecast = weatherData.list.slice(0, 4).map((item, index) => {
      const date = new Date(today);
      date.setDate(today.getDate() + index);

      const dayName =
        date.getDate() === today.getDate() ? "Today" : days[date.getDay()];
      const temperatureCelsius = Math.round(item.main.temp - 273.15);

      return (
        <div
          key={index}
          className="bg-[rgba(0,0,0,0.1)] p-4 m-4 rounded shadow-md"
        >
          <h3 className="font-semibold">{dayName}</h3>
          <p>Weather: {item.weather[0].main}</p>
          <p>Temperature: {temperatureCelsius} °C</p>
        </div>
      );
    });

    return forecast;
  };

  return (
    <Draggable nodeRef={draggableRef}>
      <div
        className="backdrop-blur-sm z-50 rounded-md absolute top-[50%] left-[50%] translate-y-[50%] translate-x-[50%]"
        ref={draggableRef}
      >
        <div className="border border-gray-300 border-dashed rounded-md dark:bg-dark bg-default flex items-center justify-center flex-col transition-colors">
          <div className="h-[30px] flex items-center justify-center cursor-move">
            <span className="dark:text-gray-100 text-default">
              <RiDragMoveFill />
            </span>
            <button
              onClick={onClose}
              className="absolute right-1 text-lg text-default   dark:text-dark flex items-center justify-between"
            >
              <IoMdClose />
            </button>
          </div>
          <div className="text-center mt-8">
            <h2 className="text-2xl font-bold mb-4">City: {city}</h2>

            {showForm && !cityError ? (
              <form onSubmit={handleCitySubmit} className="mb-4">
                <label htmlFor="city" className="mr-2">
                  Enter your city:
                </label>
                <input
                  type="text"
                  id="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                  className="mr-2"
                />
                <button
                  type="submit"
                  className="rounded-md w-[250px] text-white bg-transparent outline-none border-b-[rgba(0,0,0,0.5)]"
                >
                  Save
                </button>
              </form>
            ) : null}

            <div className="flex justify-center flex-wrap">
              {renderForecast()}
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default Weather;
