import React, { useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth, db } from "../../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";
import Header from "../../components/header";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedCourse } = location.state || {};

  const toUser = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userDocRef = doc(db, "users", userCredential.user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const username = userDocSnapshot.data().username;
        const courseToAddString = localStorage.getItem("courseToAdd");
        if (courseToAddString) {
          const courseToAdd = JSON.parse(courseToAddString);
          await updateDoc(userDocRef, {
            savedCourses: arrayUnion(courseToAdd),
          });

          // Remove the course information from local storage
          localStorage.removeItem("courseToAdd");
        }

        // Redirect to the user page
        navigate("/user", { state: { username: username } });
      } else {
        setError("User document not found");
      }
    } catch (error) {
      setError(`Login Error: ${error.message}`);
    }
  };

  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const userDocRef = doc(db, "users", userCredential.user.uid);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (userDocSnapshot.exists()) {
        const username = userDocSnapshot.data().username;
  
        // Check if the user already has the selected course in their collection
        if (selectedCourse && selectedCourse.id) {
          // Check if the courses field exists in the user document
          const userCourses = userDocSnapshot.data().courses;
          const specialKey = selectedCourse.specialKey;
  
          if (!userCourses || !userCourses.includes || !userCourses.includes(specialKey)) {
            // User does not have the course, proceed with adding it
            // Add savedCourses information to localStorage
            const courseToAdd = {
              id: selectedCourse.id,
              specialKey: selectedCourse.specialKey,
              title: selectedCourse.title,
            };
            localStorage.setItem("courseToAdd", JSON.stringify(courseToAdd));
          }
        }
  
        // Check for a stored course to add
        const courseToAddString = localStorage.getItem("courseToAdd");
  
        if (courseToAddString) {
          const courseToAdd = JSON.parse(courseToAddString);
          await updateDoc(userDocRef, {
            savedCourses: arrayUnion(courseToAdd),
          });
  
          // Remove the course information from local storage
          localStorage.removeItem("courseToAdd");
        }
  
        //console.log("User logged in with Google:", userCredential);
  
        // Redirect to the user page
        navigate("/user", { state: { username: username } });
      } else {
        setError("User document not found");
      }
    } catch (error) {
      if (error.code === "auth/cancelled-popup-request") {
        // Handle popup request cancelled by the user
        console.log("Google sign-in popup request cancelled by the user");
      } else {
        setError(`Google Sign-In Error: ${error.message}`);
      }
    }
  };
  
  

  return (
    <>
    <Header />
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-[#E0F4FF] dark:bg-[#445069] text-default dark:text-dark h-screen">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <span className="text-3xl  font-semibold">MindMingle</span>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight ">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={toUser}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 "
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 "
                >
                  Password
                </label>
                <div className="text-sm">
                  <Link
                    to="/password-reset"
                    className="font-semibold leading-6 "
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Enter your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded-md border-0 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
              >
                Sign in
              </button>
            </div>
          </form>

          <span className="mt-10 text-center text-sm ">
            Don't have an accoun?
          </span>
          <Link
            to="/signup"
            className="font-semibold leading-6"
          >
            Register
          </Link>
          <button
            onClick={googleSignIn}
            className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded mt-4 hover:bg-red-600"
          >
            <FaGoogle className="mr-2" />
            Sign in with Google
          </button>
          {error && <p className="text-red-500 mb-4">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default Login;
