import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { RiUserLine } from 'react-icons/ri';

const Dashboard = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [usernames, setUsernames] = useState([]);
  const [courseViews, setCourseViews] = useState({});
  const [userComments, setUserComments] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  const auth = getAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);
        const numberOfUsers = usersSnapshot.size;
        setTotalUsers(numberOfUsers);

        const usernamesArray = [];
        const usernamesSnapshot = await getDocs(usersCollection);
        usernamesSnapshot.forEach((userDoc) => {
          const username = userDoc.data().username;
          usernamesArray.push(username);
        });
        setUsernames(usernamesArray);

        const views = {};

        // Iterate through all users
        usersSnapshot.forEach((userDoc) => {
          const savedCourses = userDoc.data().savedCourses || [];

          // Iterate through the savedCourses of each user
          savedCourses.forEach((course) => {
            const courseId = course.title;
            views[courseId] = views[courseId] ? views[courseId] + 1 : 1;
          });
        });

        setCourseViews(views);

        onAuthStateChanged(auth, async (user) => {
          if (user) {
            setCurrentUser(user);

            // Fetch user comments
            const commentsCollection = collection(db, `users/${user.uid}/comments`);
            const commentsSnapshot = await getDocs(commentsCollection);
            const comments = [];
            commentsSnapshot.forEach((commentDoc) => {
              comments.push(commentDoc.data());
            });
            setUserComments(comments);
          } else {
            setCurrentUser(null);
          }
        });
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchUserData();
  }, [auth]);

  if (currentUser && currentUser.email === "bekzotovich12@gmail.com") {
    return (
      <div className="p-8">
        <h2 className="text-2xl font-bold mb-4">Dashboard</h2>
        <div className="mb-6">
          <p className="text-lg font-semibold">Total Users: {totalUsers}</p>
        </div>
        <div className="mb-6">
          <p className="text-lg font-semibold mb-2">Usernames:</p>
          <div className="overflow-y-scroll max-h-40 border p-2">
            {usernames.map((username, index) => (
              <div key={index} className="flex items-center mb-2">
                <RiUserLine className="mr-2" />
                {username}
              </div>
            ))}
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Course Views:</h3>
          <ul>
            {Object.entries(courseViews).map(([courseId, views]) => (
              <li key={courseId}>
                Course {courseId}: {views} views
              </li>
            ))}
          </ul>
        </div>

        {/* Display user comments */}
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">User Comments:</h3>
          <ul>
            {userComments.map((comment, index) => (
              <li key={index} className="mb-2">
                <strong>Email:</strong> {comment.email}<br />
                <strong>Message:</strong> {comment.message}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Dashboard;
