// PasswordReset.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import Header from "../../components/header";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage("Password reset email sent. Check your inbox.");
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
      setSuccessMessage("");
    }
  };

  return (
    <>
    
    <Header />
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-[#E0F4FF] dark:bg-[#445069] text-default dark:text-dark h-screen">
     
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <span className="text-3xl font-semibold">MindMingle</span>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight ">
          Password Reset
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handlePasswordReset}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 "
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full rounded-md border-0 p-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
            >
              Send Password Reset Email
            </button>
          </div>
        </form>

        <span className="mt-4 text-center text-sm">
          Remember your password?
        </span>
        <Link
          to="/login"
          className="font-semibold leading-6"
        >
          Sign in
        </Link>
        {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
        {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
      </div>
    </div>
    </>
  );
};

export default PasswordReset;
