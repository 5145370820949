import React, { useEffect, useState, useRef } from "react";
import { PiAlarmDuotone } from "react-icons/pi";
import Draggable from "react-draggable";
import { RiDragMoveFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";

const Prays = ({ onClose }) => {
  const [currentPrayer, setCurrentPrayer] = useState("");
  const draggableRef = useRef(null);

  useEffect(() => {
    const getCurrentPrayer = () => {
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const totalMinutes = hours * 60 + minutes;
      const prayerTimes = [
        { name: "Fajr", start: 300, end: 750 }, // 5:00 - 6:00
        { name: "Zuhr", start: 750, end: 930 }, // 12:30 - 15:30
        { name: "Asr", start: 935, end: 1110 }, // 15:35 - 18:30
        { name: "Maghrib", start: 1110, end: 1170 }, // 18:30 - 19:30
        { name: "Isha", start: 1175, end: 1580 }, // 19:35 - 23:00
      ];
      for (const prayer of prayerTimes) {
        if (totalMinutes >= prayer.start && totalMinutes <= prayer.end) {
          setCurrentPrayer(prayer.name);
          break;
        }
      }
    };

    getCurrentPrayer();

    // Update the current prayer every minute
    const intervalId = setInterval(() => {
      getCurrentPrayer();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Draggable nodeRef={draggableRef}>
      <div
        className="backdrop-blur-sm z-50 rounded-md absolute top-[50%] left-[50%] translate-y-[50%] translate-x-[50%]"
        ref={draggableRef}
      >
        <div className="border border-gray-300 border-dashed rounded-md dark:bg-dark bg-default flex items-center justify-center flex-col transition-colors">
          <div className="h-[30px] flex items-center justify-center cursor-move">
            <span className="dark:text-gray-100 text-default">
              <RiDragMoveFill />
            </span>
            <button
              onClick={onClose}
              className="absolute right-1 text-lg text-default dark:text-dark flex items-center justify-between"
            >
              <IoMdClose />
            </button>
          </div>
          <div className="flex p-5 flex-col items-center justify-center max-lg:gap-y-2 gap-2">
            <span className="mx-auto text-xl font-bold">Prays:</span>
            <ul className="flex items-center justify-center max-md:flex-col">
              {["Fajr", "Zuhr", "Asr", "Maghrib", "Isha"].map((prayer) => (
                <li
                  key={prayer}
                  className={`mx-2 flex items-center justify-center flex-col max-md:my-2 ${
                    currentPrayer === prayer
                      ? "font-bold p-1 border rounded-md shadow-xl"
                      : ""
                  }`}
                >
                  <span>{prayer}</span>
                  <span>
                    {prayer === "Fajr" && "5:00 - 6:00"}
                    {prayer === "Zuhr" && "12:30 - 15:30"}
                    {prayer === "Asr" && "15:35 - 18:30"}
                    {prayer === "Maghrib" && "18:30 - 19:30"}
                    {prayer === "Isha" && "19:35 - 23:00"}
                  </span>
                  {currentPrayer === prayer && (
                    <PiAlarmDuotone className="text-red-500 mt-1" />
                  )}
                </li>
              ))}
            </ul>
            <span className=" text-[0.7rem] text-default dark:text-dark italic">
              note! it is different from your timezone, this is just to remind you of
              praying time
            </span>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default Prays;
