import React, { useState, useEffect, useRef } from "react";
import alarmSound from "../assets/alarm.mp3";
import Draggable from "react-draggable";
import { RiDragMoveFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import {
  MdPlayCircleOutline,
  MdRestore,
  MdPauseCircleOutline,
} from "react-icons/md";

const PomodoroTimer = ({ onClose }) => {
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(25 * 60);
  const [minutes, setMinutes] = useState(25);
  const [seconds, setSeconds] = useState(0);
  const [totalWorkTime, setTotalWorkTime] = useState(0);
  const [isWorkSession, setIsWorkSession] = useState(true);
  const [sessionCount, setSessionCount] = useState(0);

  const draggableRef = useRef(null);

  useEffect(() => {
    let interval;

    if (isActive) {
      interval = setInterval(() => {
        if (time > 0) {
          setTime(time - 1);
          setMinutes(Math.floor(time / 60));
          setSeconds(time % 60);
        } else {
          setIsActive(false);
          setSessionCount(sessionCount + 1);
          setTotalWorkTime(totalWorkTime + (isWorkSession ? 25 : 5) * 60);

          if (sessionCount === 4) {
            setSessionCount(0);
            setIsWorkSession(false);
            setTime(30 * 60); 
          } else {
            setIsWorkSession(!isWorkSession);
            setTime(isWorkSession ? 5 * 60 : 25 * 60);
            playAlarm();
          }

          setMinutes(Math.floor(time / 60));
          setSeconds(time % 60);
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, time, totalWorkTime, isWorkSession, sessionCount]);

  const playAlarm = () => {
    const audio = new Audio(alarmSound);
    audio.play();
  };

  const toggleTimer = () => {
    setIsActive(!isActive);
  };

  const resetTimer = () => {
    setIsActive(false);
    setTotalWorkTime(0);
    setIsWorkSession(true);
    setSessionCount(0);
    setTime(25 * 60);
    setMinutes(25);
    setSeconds(0);
  };

  return (
    <Draggable nodeRef={draggableRef}>
      <div
        className="backdrop-blur-sm z-50 rounded-md absolute top-[30%] left-[30%] translate-y-[10%] translate-x-[40%]"
        ref={draggableRef}
      >
        <div className="border border-gray-300 border-dashed rounded-md shadow-md dark:bg-dark bg-default flex items-center justify-center flex-col transition-colors">
          <div className="h-[30px] flex items-center justify-center cursor-move">
            <span className="dark:text-gray-100 text-default">
              <RiDragMoveFill />
            </span>
            <button
              onClick={onClose}
              className="absolute right-1 text-lg  dark:text-dark text-default flex items-center justify-between"
            >
              <IoMdClose />
            </button>
          </div>
          <div className="text-4xl mb-4 text-default dark:text-dark">
            {String(minutes).padStart(2, "0")}:
            {String(seconds).padStart(2, "0")}
          </div>
          <div className="flex space-x-4">
            <button
              className=" dark:bg-dark  bg-default  dark:text-dark text-default px-4 py-2 rounded"
              onClick={toggleTimer}
            >
              {isActive ? <MdPauseCircleOutline /> : <MdPlayCircleOutline />}
            </button>
            <button
              className=" dark:bg-dark  bg-default  dark:text-dark text-default px-4 py-2 rounded"
              onClick={resetTimer}
            >
              <MdRestore />
            </button>
          </div>
          <div className="m-3 dark:text-white text-default">
          {totalWorkTime > 0 && (
            <div>
              Total Working Time: {Math.floor(totalWorkTime / 60)} minutes
            </div>
          )}
          </div>
          <div className="dark:text-white text-default m-3">
            Note:<span className=" text-sm font-bold"> Work (25 min):</span><br />
            <span className="text-[0.7rem] ml-1 italic">Work for 25 minutes.</span>
            <br />
            <span className=" text-sm font-bold">Short Break (5 min):</span><br />
            <span className="text-[0.7rem] ml-1 italic">Take a 5-minute break.</span><br />
            <span className=" text-sm font-bold"> Repeat:</span> <br />
            <span className="text-[0.7rem] ml-1 italic"> Repeat the work and short break cycle.</span>
            <br />
            <span className=" text-sm font-bold"> Long Break (30 min):</span> <br />
            <span className="text-[0.7rem] ml-1 italic">
              After four work sessions (100 minutes), take a 30-minute break.<br />
              Include an alarm to notify you when each phase (work or break)
              ends.
            </span>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default PomodoroTimer;
