import React, { useState, useEffect } from "react";
import {
  getAuth,
  updateEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  where,
  getDocs,
  query,
} from "firebase/firestore";
import { db } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";

const UserSettings = () => {
  const [newEmail, setNewEmail] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [newCity, setNewCity] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loggedInUsername, setLoggedInUsername] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      getDoc(userDocRef).then((userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          setLoggedInUsername(userData.username);
        }
      });
    }
  }, []);

  const handleUpdateSettings = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    const user = auth.currentUser;

    try {
      // Check if the user is logged in
      if (!user) {
        console.error("User not logged in.");
        return;
      }

      // Verify the current password
      const credentials = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credentials);

      if (newEmail) {
        await updateEmail(user, newEmail);
      }
      if (newPassword) {
        await updatePassword(user, newPassword);
      }

      // Update other details using Firestore
      if (newUsername || newCity) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();

          // Check if the new username is the same as the current username
          if (newUsername && newUsername !== userData.username) {
            const isUsernameAvailable =
              await checkUsernameAvailability(newUsername);

            if (newUsername.length > 10) {
              message.error(
                "New username should not be more than 10 characters."
              );
              return;
            }
            if (!isUsernameAvailable) {
              message.error(
                "Username is already in use. Please choose a different username."
              );
              return;
            }
          }

          // Update only the provided fields
          const updatedData = {
            username: newUsername || userData.username,
            city: newCity || userData.city,
          };

          await updateDoc(userDocRef, updatedData);
        }
      }

      message.success("User settings updated successfully");
      navigate("/user");
    } catch (error) {
      console.error("Error updating user settings:", error.message);
      setError(error.message);
    }
  };

  const checkUsernameAvailability = async (inputUsername) => {
    try {
      const userQuerySnapshot = await getDocs(
        query(collection(db, "users"), where("username", "==", inputUsername))
      );

      return userQuerySnapshot.empty; // Returns true if username is available, false otherwise
    } catch (error) {
      console.error("Error checking username availability:", error.message);
      return false;
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <span className="text-3xl text-gray-500">Brand Logo</span>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Update Your Settings
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleUpdateSettings}>
          <div>
            <label
              htmlFor="newEmail"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              New Email address
            </label>
            <div className="mt-2">
              <input
                id="newEmail"
                name="newEmail"
                type="email"
                autoComplete="newEmail"
                placeholder="Enter your new email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="newUsername"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              New Username
            </label>
            <div className="mt-2">
              <input
                id="newUsername"
                name="newUsername"
                type="text"
                autoComplete="newUsername"
                placeholder="Enter your new username"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="newCity"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              New City
            </label>
            <div className="mt-2">
              <input
                id="newCity"
                name="newCity"
                type="text"
                autoComplete="newCity"
                placeholder="Enter your new city"
                value={newCity}
                onChange={(e) => setNewCity(e.target.value)}
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="currentPassword"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Current Password
            </label>
            <div className="mt-2">
              <input
                id="currentPassword"
                name="currentPassword"
                type="password"
                placeholder="Enter your current password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                autoComplete="current-password"
              />
            </div>
            <Link
              to="/password-reset"
              className="font-semibold leading-6 text-slate-600 hover:text-slate-500"
            >
              Forgot your password?
            </Link>
          </div>

          <div>
            <label
              htmlFor="newPassword"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              New Password
            </label>
            <div className="mt-2">
              <input
                id="newPassword"
                name="newPassword"
                type="password"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-500 sm:text-sm sm:leading-6"
                autoComplete="new-password"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="confirmNewPassword"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Confirm New Password
            </label>
            <div className="mt-2">
              <input
                id="confirmNewPassword"
                name="confirmNewPassword"
                type="password"
                placeholder="Confirm your new password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-500 sm:text-sm sm:leading-6"
                autoComplete="new-password"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
            >
              Update Settings
            </button>
          </div>
        </form>

        <p className="mt-2 text-center text-sm">
          <Link to="/user" className="text-blue-500 underline">
            Cancel
          </Link>
        </p>
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
    </div>
  );
};

export default UserSettings;
