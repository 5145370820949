import React, { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import { MdOutlineWbSunny } from "react-icons/md";
import { RiMoonClearLine } from "react-icons/ri";

const DarkModeToggle = () => {
  const storedDarkMode = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(storedDarkMode ? JSON.parse(storedDarkMode) : false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    document.documentElement.classList.toggle("dark", darkMode);
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }, [darkMode]);

  return (
    <div className="flex items-center justify-center my-auto pt-2">
      <Switch
        checked={darkMode}
        onChange={toggleDarkMode}
        className={`${
          darkMode ? 'bg-gray-400' : 'bg-yellow-500'
        } relative inline-flex h-[28px] w-[56px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
      >
        <span
          aria-hidden="true"
          className={`${
            darkMode ? 'translate-x-7' : 'translate-x-0'
          } pointer-events-none h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out flex items-center justify-center text-gray-400`}
        >{darkMode ? <RiMoonClearLine className="transition-all" /> : <MdOutlineWbSunny className="transition" />}</span>
      </Switch>
    </div>
  );
};

export default DarkModeToggle;
