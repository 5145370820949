// LastViewed.js
import React from "react";
import { AiTwotoneDelete } from "react-icons/ai";

const LastViewedItem = ({ id, name, idx, onNameClick, onDelete }) => (
  <div className="mb-2 flex items-center justify-start">
    <span className="cursor-pointer text-default dark:text-dark text-sm" onClick={() => onNameClick(id)}>
      {name}
    </span>
    <button
      onClick={() => onDelete(idx)}
      className="flex items-center justify-center dark:text-dark text-default ml-3"
    >
      <AiTwotoneDelete />
    </button>
  </div>
);

const LastViewed = ({ lastViewed, onDeleteLastViewed, onNameClick }) => (
  <div className="flex items-start justify-center flex-col w-auto h-auto p-2 max-w-[300px]">
    <h2 className="text-[0.7rem] mb-1 text-gray-300 mx-auto">Last Viewed</h2>
    {lastViewed.map(({ id, name, idx }) => (
      <LastViewedItem
        key={idx}
        id={id}
        name={name}
        idx={idx}
        onDelete={onDeleteLastViewed}
        onNameClick={onNameClick}
      />
    ))}
  </div>
);

export default LastViewed;

