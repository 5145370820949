// Courses.js
import React from 'react';
import CourseList from '../components/courses/CourseList';
import { useTranslation } from "react-i18next";
import Header from '../components/header';
import Footer from '../components/Footer';

const Courses = () => {
  const { t } = useTranslation();

  return (
    <div className="font-user text-default dark:text-dark bg-[#E0F4FF] dark:bg-[#445069] w-full h-screen">
      <Header />
      <div className='mt-10 flex items-center justify-center flex-col'>
        <div className=' text-2xl font-semibold'>{t("Courses")}</div>
        <div className='text-xl'>{t("allCoursesMessage")}</div>
        <p>{t("watchCourseMessage")}</p>
        <p className='my-3 p-3'>{t("joinUsMessage")}</p>
      </div>
      <CourseList />
      <Footer />
    </div>
  );
}

export default Courses;
