import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../firebase";
import { message as antdMessage } from "antd"; 
import Header from "../components/header";
import Footer from "../components/Footer";

const Contact = () => {
    const [email, setEmail] = useState("");
    const [messageText, setMessage] = useState("");
    const [submittedData, setSubmittedData] = useState(null);
    const navigate = useNavigate();
    const [error, setError] = useState("");
  
    const sendMessage = async (e) => {
      e.preventDefault();
      if (!email || !messageText) {
        antdMessage.error("Please fill in both email and message fields.");
        return;
      }
  
      try {
        const user = auth.currentUser;
  
        if (user) {
          const commentsCollection = collection(db, `users/${user.uid}/comments`);
          await addDoc(commentsCollection, {
            email,
            message: messageText,
            timestamp: serverTimestamp(),
          });
          setSubmittedData({
            email,
            message: messageText,
          });
  
          antdMessage.success("Message sent. We'll contact you shortly.");
          setEmail("");
          setMessage("");
        } else {
          setError("User not authenticated. Please log in to leave a comment.");
        }
      } catch (error) {
        console.error("Error saving comment:", error);
        setError("Error saving comment. Please try again later.");
      }
    };
  

  return (
    <>
    <Header />
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-[#E0F4FF] dark:bg-[#445069] text-default dark:text-dark">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <span className="text-3xl text-gray-500">MindMingle</span>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Contact Us
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={sendMessage}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Message
              </label>
              <div className="mt-2">
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  placeholder="Enter your message"
                  value={messageText}
                  onChange={(e) => setMessage(e.target.value)}
                  className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
              >
                Send Message
              </button>
            </div>
            <div className="mt-5 flex items-center justify-center mx-auto cursor-pointer">
              <Link to={"/"}>Go back</Link>
            </div>
          </form>

          {error && <p className="text-red-500 mb-4">{error}</p>}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
